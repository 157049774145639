html * {
  font-family: "Arial";
  font-family: "Poppins";
  font-size: 14;
}

body {
  background-color: #f7f8fa;
  font-family: "Courier New", Courier, monospace;
}
