.any-problems {
  background: #e4e4eb;
  border-radius: 8px;
  margin: 0px 30px 0px 30px;
  padding: 15px 20px 15px 20px;
}

.any-problems__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #010747;
  padding-bottom: 5px;
}

.any-problems__content {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #010747;
}

.any-problems__call {
  color: #010747;
  display: flex;
  align-items: center;
}

.any-problems__icon {
  padding-right: 10px;
}
