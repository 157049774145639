.change-language {
  margin-top: 10px;
  padding-left: 30px;
}

.change-language > select {
  margin-bottom: 20px;
  border: none;
  background-color: transparent;
}

.change-language > select:after {
  background-color: red;
}
