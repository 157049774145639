.desktop-container {
  display: flex;
  background-color: #f7f8fa;
}

.desktop-left {
  width: 100%;
  display: flex;
  justify-content: center;
}

.desktop-left__wrapper {
  width: 375px;
}

.desktop-right {
  width: 50%;
}

.desktop-right__image {
  height: 800px;
  margin-top: 90px;
  max-height: 800px;
  margin-left: -10em;
  background-image: url("./../icon/charger-desktop.svg");
  background-position: center;
  background-repeat: no-repeat;
}
