.header {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 0%;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
}

.header__logo {
  background-image: url("./../icon/logo.svg");
  min-width: 131px;
  min-height: 33px;
  max-width: 131px;
  max-height: 33px;
  margin-bottom: 40px;
}

.header__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 100%;
  color: #2b2c35;
  margin-bottom: 65px;
}
