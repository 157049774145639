.dropdown-list {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  border-bottom: 1px solid #e4e4eb;
  padding-bottom: 25px;
  padding-top: 20px;
}

.dropdown-list__main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 9;
  padding-right: 20px;
}

.dropdown-list__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: self-end;
}

.dropdown-list__point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background: #14c364;
  border-radius: 100%;
  margin-right: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.dropdown-list__text {
  padding-right: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #2b2c35;
}

.dropdown-list--no-border {
  border: none;
}

.dropdown-list__arrow {
  width: 12px;
  height: 8px;
  background-image: url("./../icon/arrow-bottom.svg");
  cursor: pointer;
  min-width: 12px;
}

.dropdown-list__special {
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #2b2c35;
}
