.flex-d-column {
  flex-direction: column;
}

.align-items-flex-end {
  align-items: flex-end;
}

.border-bottom-radius-0 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.mt-15 {
  margin-top: 15px;
}

.d-flex {
  display: flex;
}
