.language {
  color: #010747;
  margin-top: 1vh;
}

.language__arrow {
  margin: 0 0 0.1em 0.3em;
}

.Select-arrow-zone {
  background-image: red !important;
}

.Select-arrow {
  background-image: red !important;
}
