.dropdown__store-image {
  background-image: url("./../icon/apple-store.svg");
  width: 135px;
  height: 40px;
}

.dropdown__play-image {
  background-image: url("./../icon/google-play.svg");
  width: 135px;
  height: 40px;
}

.dropdown {
  border-radius: 8px;
  width: 100%;
}

.dropdown__wrapper {
  width: 100%;
  padding-left: 40px;
}

.dropdown__content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown__collapse-text {
  padding-left: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-top: 20px;
}

.dropdown__collapse-text2 {
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding: 10px 0px 10px 0px;
}

.dropdown__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  display: flex;
  align-items: center;
}

.dropdown__arrow-bottom {
  z-index: 9;
  background-image: url("./../icon/arrow-bottom.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  min-width: 12px;
  height: 16px;
  cursor: pointer;
}

.dropdown__arrow-top {
  z-index: 9999;
  background-image: url("./../icon/arrow-top.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  min-width: 12px;
  height: 16px;
  cursor: pointer;
}

.dropdown__icon {
  padding-right: 15px;
}

.dropdown__list {
  padding-top: 15px;
}

.dropdown__list li {
  list-style-type: decimal;
}

.dropdown__list-element {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-left: 10px;
}

.dropdown__list-container {
  padding-left: 15px;
}
