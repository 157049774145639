@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}



html * {
  font-family: "Arial";
  font-family: "Poppins";
  font-size: 14;
}

body {
  background-color: #f7f8fa;
  font-family: "Courier New", Courier, monospace;
}

.header {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 0%;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
}

.header__logo {
  background-image: url(/info/static/media/logo.f22d1bdd.svg);
  min-width: 131px;
  min-height: 33px;
  max-width: 131px;
  max-height: 33px;
  margin-bottom: 40px;
}

.header__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 100%;
  color: #2b2c35;
  margin-bottom: 65px;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  border-bottom: 1px solid #e4e4eb;
  padding-bottom: 25px;
  padding-top: 20px;
}

.dropdown-list__main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 9;
  padding-right: 20px;
}

.dropdown-list__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: self-end;
}

.dropdown-list__point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background: #14c364;
  border-radius: 100%;
  margin-right: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.dropdown-list__text {
  padding-right: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #2b2c35;
}

.dropdown-list--no-border {
  border: none;
}

.dropdown-list__arrow {
  width: 12px;
  height: 8px;
  background-image: url(/info/static/media/arrow-bottom.1242393e.svg);
  cursor: pointer;
  min-width: 12px;
}

.dropdown-list__special {
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #2b2c35;
}

.dropdown__store-image {
  background-image: url(/info/static/media/apple-store.15f01eef.svg);
  width: 135px;
  height: 40px;
}

.dropdown__play-image {
  background-image: url(/info/static/media/google-play.1d8f7bc8.svg);
  width: 135px;
  height: 40px;
}

.dropdown {
  border-radius: 8px;
  width: 100%;
}

.dropdown__wrapper {
  width: 100%;
  padding-left: 40px;
}

.dropdown__content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown__collapse-text {
  padding-left: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-top: 20px;
}

.dropdown__collapse-text2 {
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding: 10px 0px 10px 0px;
}

.dropdown__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  display: flex;
  align-items: center;
}

.dropdown__arrow-bottom {
  z-index: 9;
  background-image: url(/info/static/media/arrow-bottom.1242393e.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  min-width: 12px;
  height: 16px;
  cursor: pointer;
}

.dropdown__arrow-top {
  z-index: 9999;
  background-image: url(/info/static/media/arrow-top.590d8496.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  min-width: 12px;
  height: 16px;
  cursor: pointer;
}

.dropdown__icon {
  padding-right: 15px;
}

.dropdown__list {
  padding-top: 15px;
}

.dropdown__list li {
  list-style-type: decimal;
}

.dropdown__list-element {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-left: 10px;
}

.dropdown__list-container {
  padding-left: 15px;
}

.any-problems {
  background: #e4e4eb;
  border-radius: 8px;
  margin: 0px 30px 0px 30px;
  padding: 15px 20px 15px 20px;
}

.any-problems__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #010747;
  padding-bottom: 5px;
}

.any-problems__content {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #010747;
}

.any-problems__call {
  color: #010747;
  display: flex;
  align-items: center;
}

.any-problems__icon {
  padding-right: 10px;
}

.change-language {
  margin-top: 10px;
  padding-left: 30px;
}

.change-language > select {
  margin-bottom: 20px;
  border: none;
  background-color: transparent;
}

.change-language > select:after {
  background-color: red;
}

.flex-d-column {
  flex-direction: column;
}

.align-items-flex-end {
  align-items: flex-end;
}

.border-bottom-radius-0 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.mt-15 {
  margin-top: 15px;
}

.d-flex {
  display: flex;
}

.language {
  color: #010747;
  margin-top: 1vh;
}

.language__arrow {
  margin: 0 0 0.1em 0.3em;
}

.Select-arrow-zone {
  background-image: red !important;
}

.Select-arrow {
  background-image: red !important;
}

.custom-text__container {
  width: 100%;
  padding-left: 40px;
  padding-top: 20px;
}

.custom-text1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: 190px;
  color: #2b2c35;
  padding-top: 5px;
}

.custom-text2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-top: 20px;
}



.desktop-container {
  display: flex;
  background-color: #f7f8fa;
}

.desktop-left {
  width: 100%;
  display: flex;
  justify-content: center;
}

.desktop-left__wrapper {
  width: 375px;
}

.desktop-right {
  width: 50%;
}

.desktop-right__image {
  height: 800px;
  margin-top: 90px;
  max-height: 800px;
  margin-left: -10em;
  background-image: url(/info/static/media/charger-desktop.0befbe75.svg);
  background-position: center;
  background-repeat: no-repeat;
}

