.custom-text__container {
  width: 100%;
  padding-left: 40px;
  padding-top: 20px;
}

.custom-text1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: 190px;
  color: #2b2c35;
  padding-top: 5px;
}

.custom-text2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2b2c35;
  padding-top: 20px;
}
